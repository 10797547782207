
// eslint-disable-next-line react-hooks/exhaustive-deps
import styled ,{css} from "styled-components";
import {Linea} from './lineaHomepage';
// import { Cards } from "./comoments/LineaCard/index";
import {  useRef, useState} from "react";
import okxLogo from './assets/kroma/KNS.png';
import okxLogo_Mobile from './assets/kroma/KNSMobile.png';
import { useWindowSize } from 'react-use';

const AppContainer = styled.div`
    width:100% ;
    height:100% ;
    position:relative ;
    /* overflow:auto ; */
 `
const HeaderWrap = styled.header`
position:fixed;
left:50%;
transform:translateX(-50%);
width: 100%;
padding:0 5vw 0 5vw;
height:76px;
display: flex;
justify-content: space-between;
align-items: center;
color:#000;


background:${props => props.currentpage === 1 ? "#fff" : "#fff"} ;
color:${props => props.currentpage === 1 ? "#000":"#fff"} ;
z-index:999;


`;

const LogoWrap = styled.div`
/* width: 320px; */
display: flex;
justify-content: flex-start;
align-items: center;
flex-wrap:nowrap;

font-size:30px ;
font-weight:600 ;
color:${props=>props.color};
`;

const Logo = styled.img`
  width: auto;
  height: 51px;
  margin-right: 13px;
  @media screen and (max-width:768px){
  height:46px ;
}
`;



// const Pages = styled.div`
// width:100vw;
// height:100% ;
// position:relative ;
// `
const AppButton = styled.button`
width: 171px;
height: 40px;
border-radius: 30px;
border: 1px solid #000;
background:transparent;
white-space:pre-wrap ;
color:#000;
overflow:hidden ;


/* ${props=>props.currentpage===1 && css`
  color:#000;
  border: 1px solid #000;
`}
${props=>(props.currentpage===2 || props.currentpage===3) && css`
  color:#fff;
  border: 1px solid #fff;
`} */



&:hover {
  cursor: pointer;
  background: #74D33F;
  color:#000;
  border: none;
}

a{
  display:inline-block;
  width:100%;
  height:100%;
  font-size:18px;
  line-height:40px;
  font-family: 'SF UI Text-Bold';
  font-weight:normal;
  color:#000;

  /* ${props=>(props.currentpage===1) && css`
  color:#000;
`}
  ${props=>(props.currentpage===2 || props.currentpage===3) && css`
  color:#fff;
`} */
  @media screen and (max-width: 720px){
    font-size: 16px;
  }

  @media screen and (max-width: 428px){
    font-size: 14px;
  }


  &:hover {
  cursor: pointer;
  background: #74D33F;
  color:#fff;
 
}
}
`;



function App() {

  const headerRef = useRef();
const [currentPage2, ] = useState(1);
const {width}=useWindowSize()


    return (
        <AppContainer >
            <HeaderWrap ref={headerRef} currentpage={currentPage2}>
                <LogoWrap>
                  {
                    width>768 ? <Logo src={okxLogo}></Logo>: <Logo src={okxLogo_Mobile}></Logo>
                  }
                   
                    {/* <LogoText>
                    BLAST NAME SERVICE
                    </LogoText> */}
                </LogoWrap>
                <AppButton
                    currentpage={currentPage2}
                    onClick={(e) => { 
                    e.stopPropagation();
                    window.location.href = "https://app.kromans.domains" 
                }}>
                    <a href="https://app.kromans.domains" currentpage={currentPage2}>LAUNCH  APP</a>
                </AppButton>
               {/* <a href="https://app.okbns.domains">
               <Button  onClick={(e) => { 
                    e.stopPropagation();
                    window.location.href = "https://app.okbns.domains" 
                }}>LAUNCH  APP</Button>
               </a> */}
            </HeaderWrap>
            <Linea/>
           {/* <Pages  ref={wrapperRef}>
             
              <Cards page={page}/>
           </Pages> */}
        </AppContainer>
    );
}

export default App;
