
import styled from "styled-components";
// import { useWindowSize } from "react-use";
import bg from './assets/kroma/bg.jpeg'
import kromaImg from './assets/kroma/Kroma.png';
import starImg from './assets/kroma/Star.png';
// import arrow from './assets/kroma/arrow.svg';

const Container=styled.div`
width:100% ;
position:relative ;
height:100vh;
padding-top:80px ;
background:#000e17;
overflow:hiddren ;
background-image:url(${bg}) ;
background-size:cover;
background-position:50% ;
background-repeat: no-repeat;
 `
 

// const PurpleText=styled.div`
// color:#60DFFF;
// font-family: 'Galyon-Book';
// text-align:left ;
// white-space: pre-wrap;
// `

const Data=styled.div`
position:absolute ;
bottom:62px ;
margin-top:203px;
text-align:left ;
z-index:9999 ;

@media screen and (max-width:750px){
    bottom:39px ;
}
@media screen and (max-width:450px){
    bottom:100px ;
}
/* padding-top:25% ; */
 `

const Desc=styled.div`
width:100% ;
position:absolute ;
top:45%;
left:0px;
right:0px;
transform:translateY(-50%);
/* padding-top:262px ; */
text-align:left ;
color: #1D1D1D;
font-size:60px ;
font-family: 'SF UI Text-Heavy';
line-height: 75px;
z-index:400;
display:flex ;
flex-direction:column ;
justify-content:center ;

p{
  text-align:center;
}
p:last-child{
  font-size:40px;
  font-family:'SF UI Text-Light' ;
}

i{
  font-style:normal ;
}

aside{
  padding-right:76px ;
}
@media screen and (max-height:770px) and (min-width:1002px) {
  /* padding-top:202px ; */
}
@media screen and (max-height:670px) and (min-width:1002px){
  /* padding-top:152px ; */
}
@media screen and (max-width:1500px){
  /* width:495px ; */
  font-size:46px ;
  line-height: 65px;
}

@media screen and (max-width:1280px){
 /* padding-left:117px ; */
 box-sizing:content-box ;
}

@media screen and (max-width:900px){
/* width:100% ;
height:auto ; */
/* padding-left:calc(50% - 235px); */
/* padding-top:322px ; */
}

/* 
@media screen and (max-width:1180px){
  width:435px ;
  font-size:40px ;
  line-height: 54px;

  aside{
  padding-right:26px ;
}
} */
/* 
@media screen and (max-width:950px){
  box-sizing:border-box ;
  padding-left:0px ;
}

*/
@media screen and (max-width:750px){
/* width:290px; */
/* padding-top:150px ; */
font-size:30px !important;
/* padding-left:calc(50% - 145px); */
line-height: 50px;
p:last-child{
  font-size:22px;
}
} 

@media screen and (max-width:428px){
/* width:290px; */
/* padding-top:150px ; */
  font-size:25px !important;
/* padding-left:calc(50% - 145px); */
  line-height: 40px;
  p:last-child{
    font-size:18px;
  }
} 
`

const Footer=styled.div`
/* display:inline-flex ; */
width:100% ;
margin-top: 14px;
font-size: 17px;
font-family: 'GeomGraphicW01-Regular';
font-weight: normal;
line-height: 60px;
display:flex ;
align-items:center ;
padding:23px 5vw 0 5vw;
/* white-space:pre-wrap ; */
white-space:nowrap;
word-spacing:5px;
/* &>img{
  width:30px ;
  height:auto ;
} */

a{
  display:flex ;
  align-items:center ;
  cursor: pointer;
}

&>mark::before{
  content: "&";
  font-family: 'GeomGraphicW01-Regular';
  margin:0 10px ;
}

@media screen and (max-width:750px){
    font-size:14px ;
    line-height:13px ;

    /* img.lock{
      width:25px ;
      height:25px !important ;
    } */
}

 `
const Inner=styled.div`
width:1280px ;
margin:0 auto ;
position:relative ;

main{
  height:calc(100vh - 80px);
  position:relative ;
}


@media screen and (max-width:1280px){
   width:100% ;
}

@media screen and (max-width:950px){
   main{
    width:100% ;
    display:flex ;
    justify-content:center ;
   }
}

 `

 const Inner2=styled(Inner)`
 position:absolute ;
 top:0 ;
 width:100%;
 height:100%;
 z-index:200;

 @media screen and (max-width:1500px){
    left:80px ;
  }

  @media screen and (max-width:1280px){
   left:0 ;
  }
 `


// const MantaPicture = styled.img`
// position:absolute;
// right:0 ;
// top:0;

// /* @media screen and (max-height:870px){
//   width:750px;
// }
// @media screen and (max-height:770px){
//   width:650px;
// }
// @media screen and (max-height:670px){
//   width:580px;
// } */
// @media screen and (max-width:1100px){
//   /* width:100% ;
//   height:auto ; */
// width:350px ;
//  height:auto;
//  left:50% ;
//  transform:translateX(-50%);
// }
// `

const LINK=styled.div`
width:fit-content;
position: absolute;
width:440px ;
box-sizing:border-box ;
top:95px;
left:50% ;
transform: translateX(-50%);
z-index:9999;
padding: 8px 24px;
cursor: pointer !important;

text-align: center;
font-family: 'SF UI Text-Medium';
font-size: 18px;
display:flex ;
align-items:center ;
justify-content:center ;



border-radius: 100px;
background: rgba(255, 255, 255, 0.66);
backdrop-filter: blur(3px);

/* img{
  width: 24px;
  height: 24px;

  @media screen and (max-width:430px){
    width: 20px;
    height: 20px;
  }
} */
span{
  display: inline-block;

  background: linear-gradient(90deg, #003952 0%, #007822 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  word-wrap:nowrap ;
}

@media screen and (max-width:668px){
  font-size:16px ;
  width:400px ;
}

@media screen and (max-width:430px){
  font-size:14px ;
  width:350px ;
  padding: 8px 16px;
}

@media screen and (max-width:355px){
  font-size:12px ;
  width:310px ;
  padding: 8px 16px;
}
 `
// Naming Service 
// on Mantle For Wallets, 
// Websites, and more

export function Linea(props){
  const {onEnterNextPage}=props;
  // const {width}=useWindowSize()

  /**
   * Naming Service on 
Manta For Wallets, 
Websites, and more
   */
    return (
      <Container>
        <LINK
          onClick={() =>
            window.open("https://kgh.kroma.network/buy?ref=kgh-94", "_blank")
          }
        >
         <span>Become a Kroma Guardian and Earn Rewards</span>
          
          {/* <img src={arrow} alt="" /> */}
        </LINK>
        <Inner>
          <Inner2>
            <main>
              <Desc>
                <p>Naming Service on KROMA </p>
                <p>For Wallets, Websites, and more</p>
                {/* <aside style={{width:"100%",textAlign:"right",}}>websites</aside>
                        <aside style={{width:"100%",textAlign:"right",color:"#737373"}}>on Linea</aside> */}
              </Desc>
            </main>
          </Inner2>
        </Inner>
        <Data>
          {/* <LogoCard /> */}
          <Footer onClick={(e) => onEnterNextPage(e)}>
            {/* Omni-chain Solution Powered  */}
            <h3>Powered by</h3>
            <a href="https://kroma.network/" style={{ display: "flex" }}>
              <img
                src={kromaImg}
                alt=""
                width={"75px"}
                style={{ margin: "0 5px" }}
              />
            </a>
            &
            <a href="https://star.co/">
              <img
                src={starImg}
                style={{ display: "block", margin: "0 5px" }}
                alt=""
                width={"50px"}
              />
            </a>
            {/* <h3>Layer Zero</h3> */}
            {/* <mark style={{color:"#60DFFF",background:"transparent",whiteSpace:"pre-wrap"}}></mark>
                <img style={{height:'16px',width:'auto',marginBottom:'5px'}} src={linea} alt="" /> */}
          </Footer>
        </Data>
      </Container>
    );
}




